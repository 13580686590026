import { createMuiTheme } from '@material-ui/core/styles'

const appColor = '#0078D2'

const defaultTheme = createMuiTheme()

export const muiTheme = createMuiTheme({
	palette: {
		primary1Color: appColor,
		primary2Color: appColor,
		pickerHeaderColor: appColor,
		accent1Color: appColor
	},
	overrides: {
		MuiFormLabel: {
			root: {
				fontSize: 'inherit',
				fontWeight: 700
			}
		},
		MuiFormControl: {
			root: {
				marginTop: '14px',
				[defaultTheme.breakpoints.up('md')]: {
					marginTop: '3px'
				}
			}
		},
		MuiFormControlLabel: {
			root: {
				marginBottom: 0
			}
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottomColor: '#e0e0e0'
				}
			}
		},
		MuiInputBase: {
			root: {
				fontSize: 'inherit'
			}
		},
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: '#f1f2f2'
				}
			}
		},
		MuiMenuItem: {
			root: {
				fontSize: 'inherit'
			}
		},
		MuiButton: {
			root: {
				fontSize: '14px'
			}
		},
		MuiTypography: {
			body1: {
				fontSize: '14px',
				fontWeight: 700
			},
			body2: {
				fontSize: '12px'
			},
			caption: {
				fontSize: '12px'
			},
			h4: {
				fontSize: '36px'
			},
			subtitle1: {
				fontSize: '16px'
			}
		},
		MuiDialog: {
			paperScrollPaper: {
				minHeight: '434px'
			}
		},
		MuiSvgIcon: {
			root: {
				width: '24px',
				height: '24px'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: '12px'
			}
		}
	}
})
