import asyncComponent from 'components/AsyncComponent'
import dataDependentComponent from 'components/DataDependentComponent'
import config from 'config'
import { PropTypes as PT } from 'prop-types'
import React, { Component } from 'react'
import { Grid } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'

import './App.css'

const AppActivity = dataDependentComponent(
	asyncComponent(() => import('containers/AppActivity/AppActivity')),
	state => state.terms.data && state.timezones.data
)
const Header = asyncComponent(() => import('components/Header'))

export default class App extends Component {
	static propTypes = {
		children: PT.object,
		location: PT.object
	}

	render() {
		return (
			<div className="App">
				<Switch>
					<Route
						exact
						path="/"
						render={() => {
							return (
								<div>
									<Header />
									<Grid className="App-content">
										<AppActivity />
									</Grid>
									<div className="App-footer">
										Scope v{config.version} is brought to you by{' '}
										<a
											href="https://www.purdue.edu/purdue/disclaimer.html"
											target="_blank"
											rel="noopener noreferrer">
											Purdue University
										</a>{' '}
										{String.fromCharCode(8226)}&nbsp;
										{String.fromCharCode(169) + new Date().getFullYear()} Purdue University.
										<br />
										Need help or have trouble accessing this page?{' '}
										<a href="mailto:tlt@purdue.edu">Contact us</a>.<br />
									</div>
								</div>
							)
						}}
					/>
				</Switch>
			</div>
		)
	}
}
