import { CircularProgress } from '@material-ui/core'
import { PropTypes as PT } from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

export default function dataDependentComponent(WrappedComponent, hasData) {
	class DataDependentComponent extends Component {
		static propTypes = {
			hasData: PT.bool
		}

		render() {
			return this.props.hasData ? <WrappedComponent {...this.props} /> : <CircularProgress className="loader" />
		}
	}

	const mapStateToProps = state => {
		return {
			hasData: Boolean(!!hasData && hasData(state))
		}
	}

	return withRouter(connect(mapStateToProps, null)(DataDependentComponent))
}
