import { callApi } from 'utils/api'

export const GET_TIMEZONES_REQUEST = 'timezones/GET_TIMEZONES_REQUEST'
export const GET_TIMEZONES_SUCCESS = 'timezones/GET_TIMEZONES_SUCCESS'
export const GET_TIMEZONES_FAILURE = 'timezones/GET_TIMEZONES_FAILURE'

// thunk
export const getTimezones = () => dispatch => {
	dispatch({ type: GET_TIMEZONES_REQUEST })

	return callApi('/timezones')
		.then(response => dispatch(Object.assign({}, response, { type: GET_TIMEZONES_SUCCESS })))
		.catch(error => {
			dispatch({ error, type: GET_TIMEZONES_FAILURE })
			throw error
		})
}
