import CONFIG from 'config'
import { routerMiddleware } from 'react-router-redux'
import { createStore as _createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import reducers from './reducers'

export default function createStore(initialState, history) {
	const reduxRouterMiddleware = routerMiddleware(history)
	const middlewares = [reduxRouterMiddleware, thunk]

	let composedStore
	if (CONFIG.apiDomain.indexOf('localhost') >= 0) {
		composedStore = compose(
			applyMiddleware(...middlewares, createLogger()),
			window.devToolsExtension ? window.devToolsExtension() : f => f
		)(_createStore)
	} else {
		composedStore = compose(applyMiddleware(...middlewares))(_createStore)
	}

	return composedStore(reducers, initialState)
}
