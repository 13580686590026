import appConfig from 'config'

let _store

export function authorizeConfigIfNeeded(config, requiresAuth) {
	if (!requiresAuth) {
		return Promise.resolve(config)
	}
	if (!_store.getState().auth.isAuthenticated) {
		return Promise.reject(new Error('No Authorization Data'))
	}
	const token = _store.getState().auth.data.accessToken
	if (!token) {
		return Promise.reject(new Error('No Authorization Data Access Token'))
	}
	const headers = {
		Authorization: `Bearer ${token}`
	}
	config.headers = Object.assign({}, config.headers, headers)
	return Promise.resolve(config)
}

function doCallApi(url, config, requiresAuth) {
	return authorizeConfigIfNeeded(config, requiresAuth)
		.then(authorizedConfig => {
			return fetch(url, authorizedConfig)
		})
		.then(response => {
			if (!response.ok) {
				// convert response to a JSON string, pass as Error's message
				return Promise.reject(
					new Error(
						JSON.stringify({
							ok: response.ok,
							status: response.status,
							statusText: response.statusText,
							headers: response.headers,
							body: response.body,
							bodyUsed: response.bodyUsed,
							url: response.url,
							type: response.type
						})
					)
				)
			}
			if (!response.url) {
				response.url = url
			}
			return response.json().then(json => {
				return {
					response: response,
					data: json
				}
			})
		})
}

// Setup API with a reference to the global store.getState().
export function injectStore(store) {
	_store = store
}

export function getApiUrl(appConfig, subdomain, path) {
	const domain = appConfig.apiDomain
	const scheme = domain.indexOf('localhost') === 0 ? 'http' : 'https'
	subdomain =
		subdomain === null || subdomain.indexOf('localhost') >= 0
			? ''
			: subdomain.indexOf('caliper-dev-dashboard') >= 0 || subdomain.indexOf('caliper-dashboard') >= 0
			? 'purdue.'
			: `${subdomain}.`

	return `${scheme}://${subdomain}${domain}${path}`
}

export function getSubdomainFromHostname(host) {
	const regexp = /(.+?)[:.]/g
	const match = regexp.exec(host)
	if (match === null) return null
	return match[1]
}

export function callApi(pathOrUrl, config, requiresAuth = false, isFullUrl = false) {
	const url = isFullUrl ? pathOrUrl : getApiUrl(appConfig, getSubdomainFromHostname(window.location.host), pathOrUrl)
	const localConfig = config || {
		method: 'GET',
		header: {
			Accept: 'application/json, */*'
		}
	}

	return doCallApi(url, localConfig, requiresAuth)
}
