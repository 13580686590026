import {
	AUTHORIZATION_DATA_LOADED,
	REFRESH_TOKEN_FAILURE,
	REFRESH_TOKEN_REQUEST,
	REFRESH_TOKEN_SUCCESS
} from 'redux/actions/auth'

import { LOG_IN_FAILURE, LOG_IN_NO_CREDENTIALS_PROVIDED, LOG_IN_START, LOG_IN_SUCCESS } from 'redux/actions/login'

const initialState = {
	data: null,
	isAuthenticated: false,
	isFetching: false,
	isRefreshing: false,
	isError: false,
	hasStarted: false
}

export default function auth(state = initialState, action = {}) {
	switch (action.type) {
		case LOG_IN_START:
			return Object.assign({}, state, {
				isFetching: true,
				hasStarted: true
			})

		case AUTHORIZATION_DATA_LOADED:
			return Object.assign({}, state, {
				data: action.data,
				isAuthenticated: true,
				isRefreshing: false
			})

		case REFRESH_TOKEN_REQUEST:
			return Object.assign({}, state, {
				isRefreshing: true
			})

		case REFRESH_TOKEN_SUCCESS:
		case REFRESH_TOKEN_FAILURE:
			return Object.assign({}, state, {
				isRefreshing: false
			})

		case LOG_IN_FAILURE:
			return Object.assign({}, state, {
				data: null,
				isAuthenticated: false,
				isFetching: false,
				isRefreshing: false,
				isError: true
			})

		case LOG_IN_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				isRefreshing: false,
				isAuthenticated: true
			})

		case LOG_IN_NO_CREDENTIALS_PROVIDED:
			return Object.assign({}, state, {
				isFetching: false,
				isRefreshing: false,
				isAuthenticated: false
			})

		default:
			return state
	}
}
