import config from 'config'
import { callApi } from 'utils/api'

/**
 *	OAuth Token
 */

export const CODE_EXCHANGE_REQUEST = 'auth/CODE_EXCHANGE_REQUEST'
export const CODE_EXCHANGE_SUCCESS = 'auth/CODE_EXCHANGE_SUCCESS'
export const CODE_EXCHANGE_FAILURE = 'auth/CODE_EXCHANGE_FAILURE'
export const AUTHORIZATION_DATA_LOADED = 'auth/AUTHORIZATION_DATA_LOADED'

export const authorizationDataLoaded = authorizationData => {
	return {
		type: AUTHORIZATION_DATA_LOADED,
		data: authorizationData
	}
}

const parseAndSaveAuthorizationData = data => {
	const authorizationData = {
		accessToken: data.access_token,
		refreshToken: data.refresh_token,
		expires: new Date(data['.expires']),
		issued: new Date(data['.issued'])
	}
	return authorizationData
}

// thunk
export const getAuthorizationDataFromOauthCode = (oauthCode, redirectUri) => dispatch => {
	const endpoint = '/oauth/token'
	const postData =
		'grant_type=authorization_code' +
		'&code=' +
		encodeURIComponent(oauthCode) +
		'&client_id=' +
		config.clientId +
		'&client_secret=' +
		config.clientSecret +
		'&redirect_uri=' +
		redirectUri
	const requestConfig = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: postData
	}
	dispatch({ type: CODE_EXCHANGE_REQUEST })
	return callApi(endpoint, requestConfig)
		.then(action => {
			const authorizationData = parseAndSaveAuthorizationData(action.data)
			dispatch(authorizationDataLoaded(authorizationData))
			dispatch({ type: CODE_EXCHANGE_SUCCESS })
		})
		.catch(error => {
			dispatch({ type: CODE_EXCHANGE_FAILURE })
			throw error
		})
}

export const REFRESH_TOKEN_REQUEST = 'auth/REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE'

// thunk
export const refreshAuthorizationData = refreshToken => dispatch => {
	const endpoint = '/token'
	const postData =
		'grant_type=refresh_token' +
		'&refresh_token=' +
		encodeURIComponent(refreshToken) +
		'&client_id=' +
		config.clientId +
		'&client_secret=' +
		config.clientSecret
	const requestConfig = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: postData
	}

	dispatch({ type: REFRESH_TOKEN_REQUEST })
	return callApi(endpoint, requestConfig)
		.then(response => {
			const authorizationData = parseAndSaveAuthorizationData(response.data)
			return authorizationData
		})
		.then(data => dispatch({ type: REFRESH_TOKEN_SUCCESS, data }))
		.catch(error => {
			dispatch(Object.assign({}, error, { type: REFRESH_TOKEN_FAILURE }))
			throw error
		})
}

// thunk
export const refreshAuthorizationDataIfNeeded = () => (dispatch, getStore) => {
	if (!getStore().auth.isAuthenticated) {
		return Promise.resolve()
	}
	if (!getStore().auth.isExpired) {
		return Promise.resolve()
	}
	return dispatch(refreshAuthorizationData(getStore().auth.data.refreshToken))
}
