import { version } from '../package.json'

const windowEnv = window._env_ || {}
const processEnv = process.env || {}

let allowedAppIds = windowEnv.REACT_APP_ALLOWED_APP_IDS || processEnv.REACT_APP_ALLOWED_APP_IDS
if (!!allowedAppIds && allowedAppIds.length > 0) allowedAppIds = allowedAppIds.split(',')
else allowedAppIds = undefined

export default {
	version: version,

	// EventStore API
	apiDomain: windowEnv.REACT_APP_API_DOMAIN || processEnv.REACT_APP_API_DOMAIN || 'localhost:3001',

	// Term API
	termsApiEndpoint: windowEnv.REACT_APP_TERMS_API_ENDPOINT || processEnv.REACT_APP_TERMS_API_ENDPOINT || '',

	// Application Insights
	appInsightsKey:
		windowEnv.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY || processEnv.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY || '',

	// Sentry Error Logging
	sentryDSN: windowEnv.REACT_APP_SENTRY_DSN || processEnv.REACT_APP_SENTRY_DSN || '',

	// Google Analytics
	googleAnalytics: windowEnv.REACT_APP_GOOGLE_ANALYTICS || processEnv.REACT_APP_GOOGLE_ANALYTICS || '',

	// OAuth
	clientId: 'scope',
	clientSecret: windowEnv.REACT_APP_OAUTH_CLIENT_SECRET || processEnv.REACT_APP_OAUTH_CLIENT_SECRET || 'SECRETKEY',

	// Whitelisted apps for display
	allowedAppIds
}
