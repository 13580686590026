import { routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux'
import auth from './auth'
import terms from './terms'
import timezones from './timezones'

export default combineReducers({
	auth,
	timezones,
	terms,
	router: routerReducer
})
