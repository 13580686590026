import { getAuthorizationDataFromOauthCode } from './auth'

export const LOG_IN_START = 'login/LOG_IN_START'
export const LOG_IN_SUCCESS = 'login/LOG_IN_SUCCESS'
export const LOG_IN_FAILURE = 'login/LOG_IN_FAILURE'
export const LOG_IN_NO_CREDENTIALS_PROVIDED = 'login/LOG_IN_NO_CREDENTIALS_PROVIDED'

export const logInStart = () => {
	return {
		type: LOG_IN_START
	}
}

export const logInSuccess = () => {
	return {
		type: LOG_IN_SUCCESS
	}
}

export const logInFailure = error => {
	return {
		type: LOG_IN_FAILURE,
		error
	}
}

export const logInNoCredentialsProvided = () => {
	return {
		type: LOG_IN_NO_CREDENTIALS_PROVIDED
	}
}

const logInWithOauthCode = (oauthCode, redirectUri, dispatch) => {
	return dispatch(getAuthorizationDataFromOauthCode(oauthCode, redirectUri))
}

export const logIn = (oauthCode = null, redirectUri = null) => dispatch => {
	// if (getState().auth.isAuthenticated || getState().auth.isFetching || getState().auth.isRefreshing) {
	// 	return Promise.resolve();
	// }
	let loginAction = null
	dispatch(logInStart())
	if (oauthCode && redirectUri) {
		loginAction = logInWithOauthCode(oauthCode, redirectUri, dispatch)
	}
	if (loginAction) {
		return loginAction
			.then(() => dispatch(logInSuccess()))
			.catch(error => {
				dispatch(logInFailure(error))
			})
	} else {
		dispatch(logInNoCredentialsProvided())
	}
	return Promise.resolve()
}
