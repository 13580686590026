import { GET_TERMS_FAILURE, GET_TERMS_REQUEST, GET_TERMS_SUCCESS } from 'redux/actions/terms'

const initialState = {
	data: null,
	isFetching: false,
	isError: false
}

export default function terms(state = initialState, action = {}) {
	switch (action.type) {
		case GET_TERMS_REQUEST:
			return Object.assign({}, state, {
				data: null,
				isFetching: true,
				isError: false
			})

		case GET_TERMS_SUCCESS: {
			return Object.assign({}, state, {
				data: action.data,
				isFetching: false,
				isError: false
			})
		}

		case GET_TERMS_FAILURE:
			return Object.assign({}, state, {
				data: null,
				isFetching: false,
				isError: true
			})

		default:
			return state
	}
}
