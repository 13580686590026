/* eslint-disable import/first */
/* eslint-disable import/order */
import 'react-app-polyfill/ie11'

import { createGenerateClassName, jssPreset, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import * as Sentry from '@sentry/browser'
import config from 'config'
import { createBrowserHistory } from 'history'
import { create } from 'jss'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { getTerms } from 'redux/actions/terms'
import { getTimezones } from 'redux/actions/timezones'
import createStore from 'redux/createStore'
import { injectStore } from 'utils/api'
import { muiTheme } from './theme-override'

//#region CSS

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap-theme.min.css'
import './css/index.css'

//#endregion CSS

//#region JSS + material-ui

const generateClassName = createGenerateClassName()
const insertionPoint = document.getElementById('jss-insertion-point')
const jss = create({
	...jssPreset(),
	// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
	insertionPoint: insertionPoint || '#jss-insertion-point'
})

//#endregion JSS + material-ui

//#region Startup

// Application Insights
let appInsights
if (config.appInsightsKey) {
	appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: config.appInsightsKey
		}
	})
	appInsights.loadAppInsights()
}

// Sentry
if (config.sentryDSN) {
	Sentry.init({ dsn: config.sentryDSN, release: config.version })
}

// Create History
const history = createBrowserHistory()
history.listen((location, action) => {
	// send pageview to Application Insights
	if (appInsights) {
		appInsights.trackPageView({ uri: location.pathname })
	}
	// handle anchor link scrolling
	if (location.action === 'PUSH') {
		const hash = location.hash.substring(1) || ''
		const element = hash.length > 0 ? document.querySelectorAll(`[name=${hash}]`)[0] : null
		if (element) {
			element.scrollIntoView()
		} else {
			document.getElementById('root').scrollIntoView()
		}
	}
})

const store = createStore({}, history)
injectStore(store)

// Global Data
store.dispatch(getTerms())
store.dispatch(getTimezones())

//#endregion Startup

// (imported after other css)
import { App } from 'containers/App'

ReactDOM.render(
	<StylesProvider jss={jss} generateClassName={generateClassName}>
		<MuiThemeProvider theme={muiTheme}>
			<Provider store={store} key="provider">
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</Provider>
		</MuiThemeProvider>
	</StylesProvider>,
	document.getElementById('root')
)
