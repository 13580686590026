import appConfig from 'config'
import moment from 'moment-timezone'
import { callApi } from 'utils/api'

export const GET_TERMS_REQUEST = 'timezones/GET_TERMS_REQUEST'
export const GET_TERMS_SUCCESS = 'timezones/GET_TERMS_SUCCESS'
export const GET_TERMS_FAILURE = 'timezones/GET_TERMS_FAILURE'

// thunk
export const getTerms = () => dispatch => {
	dispatch({ type: GET_TERMS_REQUEST })

	return callApi(appConfig.termsApiEndpoint, null, false, true)
		.then(response =>
			dispatch(
				// only the most recent terms (including the current term)
				// sorted in descending order (most recent first)
				Object.assign(
					{},
					{
						data: response.data
							.map(t => {
								return {
									startMoment: moment.utc(t.startDate),
									endMoment: moment.utc(t.endDate)
								}
							})
							.filter(t => moment().isSameOrAfter(t.startMoment))
							.sort((t1, t2) => t2.startMoment.diff(t1.endMoment))
					},
					{ type: GET_TERMS_SUCCESS }
				)
			)
		)
		.catch(error => {
			// load estimatedTerms if failed to fetch from API
			dispatch(Object.assign({}, { data: estimatedTerms() }, { type: GET_TERMS_SUCCESS }))
		})
		.catch(error => {
			dispatch({ error, type: GET_TERMS_FAILURE })
			throw error
		})
}

const estimatedTerms = () => {
	const now = moment()
	let year = now.year()
	const lastFallStart = moment({ y: year - 1, M: 7, d: 15 }).startOf('day')
	const springStart = moment({ y: year, M: 0, d: 1 }).startOf('day')
	const springEnd = moment({ y: year, M: 4, d: 14 }).endOf('day')
	const fallStart = moment({ y: year, M: 7, d: 15 }).startOf('day')
	const fallEnd = moment({ y: year, M: 11, d: 31 }).endOf('day')

	const lastFall = {
		startMoment: lastFallStart,
		endMoment: fallStart
	}
	const spring = {
		startMoment: springStart,
		endMoment: springEnd
	}
	const fall = {
		startMoment: fallStart,
		endMoment: fallEnd
	}
	if (now.isSameOrBefore(springEnd)) {
		return [lastFall, spring]
	}
	return [spring, fall]
}
